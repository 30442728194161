<script setup lang="ts">
/* import */
import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'
import BaseSvgIcon from '@/components/Base/SvgIcon.vue'
import IndexBaseBarChart from './IndexBaseBarChart.vue'
import { useDate } from '@/composables/useDate'
import { useFetchStore } from '@/composables/useFetchStore'
import { useModal } from '@/composables/useModal'
import AccountInquiryFilterModal from '@/components/DashboardAccountInquiryPage/Modal/AccountInquiryFilterModal.vue'

/* type */
type OperationDataKey = 'revenue' | 'prizeWinCount' | 'profit' | 'coinExchanged'

/* 非響應式變數 */
const TABS = {
  REVENUE: 'revenue',
  PROFIT: 'profit'
}

/* i18n */
const { t: $t } = useI18n()

/* ref 變數 */
const nowTopic = ref<'revenue' | 'profit'>(TABS.REVENUE as 'revenue' | 'profit')
const updateTime = ref('')

// 初始化日期
const { today, getCurrentDateTime, getDayOfWeek, calculateWeekStartDate } = useDate()
updateTime.value = getCurrentDateTime()

const isInitial = ref(true)
const dataStartDate = ref(calculateWeekStartDate())
const dataEndDate = ref(today())

const { modalVisible, openModal, closeModal } = useModal()
const isModalVisible = ref(false)
const resetKey = ref(0)

// 獲取數據
const { operationTotalChart, fetchTotalOperationChart } = useFetchStore()

/* computed */
const calculateTotalForToday = (key: OperationDataKey) => {
  return computed(() => {
    const totalData = operationTotalChart.value.data || []
    const todayDate = today()
    const todayData = totalData.filter((entry) => {
      const entryDate = new Date(entry.date)
      return entryDate.toDateString() === new Date(todayDate).toDateString()
    })
    return todayData.reduce((total, entry) => total + (entry[key] || 0), 0)
  })
}

const calculateTotalForWeek = (key: OperationDataKey) => {
  return computed(() => {
    const dayNumber = getDayOfWeek(today())

    const totalData = operationTotalChart.value.data?.slice(dayNumber > 0 ? -dayNumber : 0) || []
    return totalData.reduce((total, entry) => total + (entry[key] || 0), 0)
  })
}

const revenueDataForWeek = computed(() => `${calculateTotalForWeek('revenue').value}`)
const revenueDataForToday = computed(() => `${calculateTotalForToday('revenue').value}`)

// const prizeWinCountForWeek = calculateTotalForWeek('prizeWinCount')
const prizeWinCountForToday = calculateTotalForToday('prizeWinCount')

const profitForWeek = computed(() => `${calculateTotalForWeek('profit').value}`)
const profitForToday = computed(() => `${calculateTotalForToday('profit').value}`)

// const coinExchangedForWeek = computed(() => `${calculateTotalForWeek('coinExchanged').value}`)
const coinExchangedForToday = computed(() => `${calculateTotalForToday('coinExchanged').value}`)

const grossMarginForToday = computed(() => {
  if (profitForToday.value === '0') {
    return 0
  }

  return (Number(profitForToday.value) / Number(revenueDataForToday.value)) * 100
})

/* functions */
function fnChangeTab(topic: 'revenue' | 'profit') {
  nowTopic.value = topic
}

const resetModalVisible = (modalVisibility: boolean): boolean => {
  return modalVisibility
}

const handleOpenModal = (): void => {
  isModalVisible.value = resetModalVisible(isModalVisible.value)

  openModal(() => {
    isModalVisible.value = true
  })
}

const fnRefreshDashboard = (data: { startDate: string; endDate: string }) => {
  fnResetData(data)
}

const fnResetData = async (data?: { startDate: string; endDate: string }) => {
  isInitial.value = data ? false : true

  dataStartDate.value = data?.startDate || calculateWeekStartDate()
  dataEndDate.value = data?.endDate || today()

  if (!data) {
    resetKey.value += 1
  }

  fetchTotalOperationChart({
    startDate: dataStartDate.value,
    endDate: dataEndDate.value
  })
}

const isTodayData = computed(() => {
  const isSameStartEnd = dataStartDate.value === dataEndDate.value
  const isToday = dataStartDate.value === today()

  return isSameStartEnd && isToday
})

const dateDisplacer = computed(() => {
  if (isTodayData.value) {
    return `${dataStartDate.value}`
  }

  return isInitial.value
    ? $t('HomePage.Chart.Week')
    : `${dataStartDate.value} ~ ${dataEndDate.value}`
})

fetchTotalOperationChart({ startDate: dataStartDate.value, endDate: dataEndDate.value })
</script>

<template>
  <div class="bar-chart-tab">
    <div
      class="tab-item revenue"
      :class="{ active: nowTopic === TABS.REVENUE }"
      @click="fnChangeTab(TABS.REVENUE as 'revenue')"
    >
      {{ $t('HomePage.Chart.Revenue') }}
    </div>
    <div
      class="tab-item profit"
      :class="{ active: nowTopic === TABS.PROFIT }"
      @click="fnChangeTab(TABS.PROFIT as 'profit')"
    >
      {{ $t('HomePage.Chart.Profit') }}
    </div>
  </div>
  <div class="bar-chart-container">
    <div class="chart-section">
      <div class="data-filter-section">
        <div class="days-section">
          <div class="revenue-week">
            {{ dateDisplacer }}&nbsp;{{
              nowTopic === TABS.REVENUE ? $t('HomePage.Chart.Revenue') : $t('HomePage.Chart.Profit')
            }}
            <p class="revenue-data">
              {{ nowTopic === TABS.REVENUE ? revenueDataForWeek : profitForWeek }}
            </p>
          </div>
          <div class="revenue">
            {{ $t('HomePage.Chart.Today') }}&nbsp;{{
              nowTopic === TABS.REVENUE ? $t('HomePage.Chart.Revenue') : $t('HomePage.Chart.Profit')
            }}
            <p class="revenue-data">
              {{ nowTopic === TABS.REVENUE ? revenueDataForToday : profitForToday }}
            </p>
          </div>
        </div>

        <div
          class="filter-button"
          @click="handleOpenModal"
        >
          <BaseSvgIcon
            iconName="filter-menu-sm"
            size="lg"
          />
        </div>
      </div>

      <div class="chartData-section">
        <IndexBaseBarChart
          class="chart"
          :isLoading="operationTotalChart.isLoading"
          :data="operationTotalChart.data"
          :tabPage="nowTopic"
        />
        <div class="chartData-container">
          <div class="data-title">
            <div class="title">
              {{ $t('HomePage.Chart.PrizeWinCount') }}
              <BaseSvgIcon
                iconName="dropdown"
                color="white"
              />
            </div>
            <div class="data">{{ prizeWinCountForToday }}</div>
          </div>
          <div class="data-title">
            <div class="title">
              {{ $t('HomePage.Chart.Profit') }}
              <BaseSvgIcon
                iconName="dropdown"
                color="white"
              />
            </div>
            <div class="data">
              {{ profitForToday }}
            </div>
          </div>
          <div class="data-title">
            <div class="title">
              {{ nowTopic === 'revenue' ? $t('HomePage.Chart.CoinExchanged') : '毛利率' }}
              <BaseSvgIcon
                iconName="dropdown"
                color="white"
              />
            </div>
            <div class="data">
              {{ nowTopic === 'revenue' ? coinExchangedForToday : grossMarginForToday }}
              {{ nowTopic === 'revenue' ? $t('Common.Coins') : '%' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AccountInquiryFilterModal
    v-if="isModalVisible"
    :modal-visible="modalVisible"
    :resetAll="resetKey"
    @close="closeModal()"
    @refresh:account-inquiry-dashboard="fnRefreshDashboard"
  />
</template>

<style lang="scss" scoped>
.bar-chart-tab {
  display: flex;
  column-gap: 8px;
  padding-left: 1rem;
  .tab-item {
    padding: 8px 40px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    letter-spacing: 3px;
    user-select: none;
    cursor: pointer;
    color: $--color-targetTab-bg;
    background-color: $--color-Tab-bg;
    border: 2px solid $--color-targetTab-bg;
    border-bottom: none;

    &.active {
      background-color: $--color-targetTab-bg;
      color: $--color-white;
    }
  }
}
.bar-chart-container {
  background-color: $--color-targetTab-bg;
  border-radius: $--border-radius-extraLarge;
  padding: 1rem;
  display: flex;
  column-gap: 2rem;

  .revenue-week {
    font-size: 1rem;
    font-weight: 400;
    color: #fbf6e5;
    line-height: 1.4;
    margin-bottom: 1rem;
    &-data {
      font-size: 2rem;
      font-weight: 700;
    }
  }

  .revenue {
    font-size: 1rem;
    font-weight: 400;
    color: #ffedad;
    line-height: 1.4;
    margin-bottom: 1rem;
    &-data {
      font-size: 2rem;
      font-weight: 700;
    }
  }

  .data-filter-section {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;

    .filter-button {
      cursor: pointer;
    }
  }

  .days-section {
    display: flex;
    gap: 1rem;
  }

  .chart-section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .chartData-section {
    display: flex;
    column-gap: 1.5rem;
    @include media-breakpoint-down(sm) {
      align-items: center;
    }
    .chart {
      width: 100%;
      aspect-ratio: 2 / 1;
      flex: 6;
    }
    .chartData-container {
      flex: 2;
      font-weight: 700;
      color: $--color-white;
      @include media-breakpoint-down(xs) {
        font-size: 0.75rem;
      }
    }

    .data-title {
      margin-bottom: 0.5rem;
      .title {
        display: flex;
        white-space: nowrap;
        align-items: center;
        justify-content: space-between;
        > * {
          flex-shrink: 0;
        }
      }
      .data {
        width: 100%;
      }
    }
  }
}
</style>
